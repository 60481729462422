<template>
  <admin>
    <metatag title="Text Blasts Batches"></metatag>
    <page-header>
      <template v-slot:action>
        <can :code="code">
          <v-btn
            :to="{ name: 'text-blast.create' }"
            :block="$vuetify.breakpoint.smAndDown"
            large
            color="primary"
            exact
            class="mx-5 mx-md-2 mt-2 mt-md-0 white--text"
          >
            <v-icon small left>mdi-plus-circle</v-icon>
            Add new
          </v-btn>
        </can>
        <!-- <v-btn class="ml-3" :to="{ name: 'infobip' }" text icon>
          <v-icon>mdi-cog</v-icon>
        </v-btn> -->
      </template>
    </page-header>
    <v-card>
      <toolbar-menu
        ref="toolbar"
        :items.sync="tabletoolbar"
        @update:search="setSearch"
      ></toolbar-menu>
      <v-card-text class="pa-0">
        <v-data-table
          :headers="getTableHeaders()"
          :items="items"
          :options.sync="options"
          :server-items-length="total"
          :loading="loading"
          :items-per-page="5"
          color="primary"
          item-key="id"
          class="text-no-wrap"
        >
          <template v-slot:item.id="{ item }">
            <v-btn text color="primary" icon>
              {{ item.id }}
            </v-btn>
          </template>

          <template v-slot:item.name="{ item }">
            {{ item.name }} <br />
            <small>{{ item.dealer_name }}</small>
          </template>

          <template v-slot:item.survey_name="{ item }">
            <small>{{ item.survey_name }}</small> <br />
          </template>

          <template v-slot:item.status="{ item }">
            <v-chip label>{{ item.status }}</v-chip>
            <br v-if="item.scheduled_at" />

            <v-tooltip v-if="item.scheduled_at" bottom>
              <template v-slot:activator="{ on }">
                <small
                  v-on="on"
                  style="font-size: 12px"
                  v-if="item.scheduled_at"
                >
                  <v-icon small left>mdi-clock</v-icon>
                  {{ formatDate(item.scheduled_at) }}
                </small>
              </template>
              <span>The scheduled date for sending</span>
            </v-tooltip>
          </template>

          <template v-slot:item.created_at="{ item }">
            <span>{{ formatDate(item.created_at) }}</span>
          </template>

          <template v-slot:item.deadline_at="{ item }">
            <v-icon
              v-if="item.deadline_at"
              :color="getDeadlineColor(item.days_before_deadline_count)"
            >
              {{ getDeadlineIcon(item.days_before_deadline_count) }} small
            </v-icon>
            &nbsp;
            <small
              v-if="item.deadline_at"
              :class="
                getDeadlineColor(item.days_before_deadline_count) + '--text'
              "
            >
              {{ formatDate(item.deadline_at) }}
            </small>
          </template>

          <template v-slot:item.progress="{ item }">
            <h4>
              {{ item.count_survey_results }} /
              {{ item.target_recipients_count }}
              <span style="float: right; color: rgba(0, 0, 0, 0.38)"
                >{{ item.respondents_percentage }}%</span
              >
            </h4>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-progress-linear
                  v-on="on"
                  rounded
                  :value="item.respondents_percentage"
                  height="8"
                >
                </v-progress-linear>
              </template>
              <span
                v-text="
                  `${item.target_recipients_count} (${item.respondents_percentage}%)`
                "
              ></span>
            </v-tooltip>
          </template>
          <!-- Action buttons -->
          <template v-slot:item.actions="{ item }">
            <!-- Send -->
            <v-tooltip
              v-if="
                item.type === 'dealer_send' &&
                ![
                  'completed',
                  'queue_sending',
                  'sending',
                  'initializing',
                ].includes(item.status)
              "
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  v-on="on"
                  icon
                  @click="showBatchHistory(item, 'send')"
                >
                  <v-icon small>mdi-send</v-icon>
                </v-btn>
              </template>
              <span v-text="'Send'"></span>
            </v-tooltip>

            <!-- Resend -->
            <v-tooltip v-if="item.status === 'completed'" bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  v-on="on"
                  icon
                  @click="showBatchHistory(item, 'resend')"
                >
                  <v-icon small>mdi-sync</v-icon>
                </v-btn>
              </template>
              <span v-text="'Resend'"></span>
            </v-tooltip>

            <!-- View Logs -->
            <v-tooltip v-if="item.status === 'completed'" bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  icon
                  v-on="on"
                  :to="{ name: 'text-blast.logs', params: { id: item.id } }"
                >
                  <v-icon small>mdi-view-list</v-icon>
                </v-btn>
              </template>
              <span v-text="'View Logs'"></span>
            </v-tooltip>

            <!-- Download -->
            <v-tooltip v-if="item.status === 'completed'" bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  :loading="downloading && downloadId == item.id"
                  :disabled="downloading && downloadId == item.id"
                  @click="download(item)"
                  v-on="on"
                  text
                  icon
                >
                  <v-icon small>mdi-download</v-icon>
                </v-btn>
              </template>
              <span v-text="'Download Report'"></span>
            </v-tooltip>
          </template>
          <!-- Action buttons -->
        </v-data-table>
      </v-card-text>
    </v-card>

    <template v-if="previewDialog">
      <v-dialog
        v-model="previewDialog"
        max-width="900px"
        persistent
        class="overflow-hidden"
      >
        <v-card class="pa-4">
          <v-card-title>
            <h2 title="Text Blast Name" class="mb-1">
              <span class="text-capitalize">{{ sendMethod }}</span>
              {{ dialogItem.name }}
            </h2>
          </v-card-title>
          <v-card-text class="overflow-y-auto">
            <v-stepper v-model="e6" vertical>
              <v-stepper-step :complete="e6 > 1" step="1">
                Text Blast Details
              </v-stepper-step>

              <v-stepper-content step="1">
                <v-card border="0" class="mb-12">
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <td class="font-weight-bold">{{ "Survey Name" }}</td>
                        <td>{{ dialogItem.survey_name }}</td>
                      </tr>
                      <!-- <tr>
                        <td class="font-weight-bold">{{ "Type" }}</td>
                        <td>{{ "D-SSI" }}</td>
                      </tr> -->
                      <tr>
                        <td class="font-weight-bold">
                          {{ "Total no. of recipients" }}
                        </td>
                        <td>{{ dialogItem.target_recipients_count }}</td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">
                          {{ `Target to ${sendMethod} ` }}
                        </td>
                        <td>
                          {{
                            `${
                              dialogItem.target_recipients_count -
                              dialogItem.count_survey_results
                            } (${
                              sendMethod === "resend"
                                ? dialogItem.resend_respondents_percentage
                                : dialogItem.respondents_percentage
                            }%)`
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>
                <v-btn color="primary" @click="e6 = 2"> Continue </v-btn>
              </v-stepper-content>

              <v-stepper-step :complete="e6 > 2" step="2">
                Message
              </v-stepper-step>

              <v-stepper-content step="2">
                <code
                  class="d-block mb-5"
                  style="background-color: transparent"
                >
                  {{ dialogItem.message }}
                </code>
                <v-btn color="primary" @click="e6 = 3"> Continue </v-btn>
                <v-btn class="mx-2" text @click="e6 = e6 - 1"> Back </v-btn>
              </v-stepper-content>

              <v-stepper-step :complete="e6 > 3" step="3">
                Confirm
              </v-stepper-step>

              <v-stepper-content step="3">
                <v-alert class="mb-5" text type="error">
                  Are you sure you want to proceed {{ `${sendMethod}ing` }} this
                  batch to
                  <strong>{{
                    dialogItem.target_recipients_count -
                    dialogItem.count_survey_results
                  }}</strong>
                  recipients? <br />
                  <strong>This process cannot be undone.</strong>
                </v-alert>
                <v-btn
                  :loading="sendLoading"
                  :disabled="sendLoading"
                  color="primary"
                  @click="sendSmsBlast(dialogItem, sendMethod)"
                >
                  Submit
                </v-btn>
                <v-btn class="mx-2" text @click="e6 = e6 - 1"> Back </v-btn>
              </v-stepper-content>
            </v-stepper>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              color="grey"
              exact
              class="ma-1 white--text px-5"
              @click="previewDialog = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </admin>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as helpers from "@/core/helpers";
import { debounce } from "lodash";

export default {
  data() {
    return {
      tabletoolbar: {
        isSearching: false,
        search: null,
        models: [],
      },
      selectedItem: {},
      code: ["admin", "admin_survey"],
      loading: true,
      options: {},
      items: [],
      total: 0,
      valid: true,
      loadingSave: false,
      downloading: false,
      downloadId: null,
      q: "",
      tableData: {
        headers: {
          admin: [
            {
              text: "ID",
              align: "center",
              value: "id",
              sortable: true,
            },
            {
              text: "Name",
              align: "left",
              value: "name",
              sortable: false,
            },
            {
              text: "Survey",
              align: "left",
              value: "survey_name",
              sortable: false,
            },
            {
              text: "Respondents",
              align: "left",
              value: "progress",
              sortable: false,
            },
            {
              text: "Status",
              align: "left",
              value: "status",
              sortable: false,
            },
            {
              text: "Deadline",
              align: "left",
              value: "deadline_at",
              sortable: false,
            },
            {
              text: "Date Created",
              align: "left",
              value: "created_at",
              sortable: true,
            },
            {
              text: "Actions",
              align: "left",
              value: "actions",
              sortable: false,
            },
          ],
          dealer: [
            {
              text: "ID",
              align: "center",
              value: "id",
              sortable: false,
            },
            {
              text: "Name",
              align: "left",
              value: "name",
              sortable: false,
            },
            {
              text: "Survey",
              align: "left",
              value: "survey_name",
              sortable: false,
            },
            {
              text: "Respondents",
              align: "left",
              value: "progress",
              sortable: false,
            },
            {
              text: "Status",
              align: "left",
              value: "status",
              sortable: false,
            },
            {
              text: "Deadline",
              align: "left",
              value: "deadline_at",
              sortable: false,
            },
            {
              text: "Date Created",
              align: "left",
              value: "created_at",
              sortable: true,
            },
            {
              text: "Actions",
              align: "left",
              value: "actions",
              sortable: false,
            },
          ],
        },
      },
      previewDialog: false,
      dialogItem: {},
      sendLoading: false,
      e6: 1,
      sendMethod: null,
    };
  },
  watch: {
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        const orderBy = sortBy.length ? sortBy[0] : "created_at";
        const sortDir = !sortDesc[0] ? "DESC" : "ASC";
        this.getItems(page, itemsPerPage, this.q, orderBy, sortDir);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      data: "surveySmsBlasts/GET_SMS_TEXT_BLASTS",
      userRoles: "auth/GET_ROLES",
    }),
  },
  async mounted() {
    await this.getUserType();
  },
  methods: {
    ...mapActions({
      getSurveySmsBlasts: "surveySmsBlasts/list",
      getUserType: "auth/getUserType",
      updateInventory: "surveySmsBlasts/updateInventory",
      sendSurveySmsBlast: "surveySmsBlasts/send",
      downloadTextBlastLogs: "survey/downloadTextBlastLogs",
    }),

    getTableHeaders() {
      // Set user type
      let user_roles = this.userRoles.map(a => a.slug);

      if (user_roles.includes("dealer_survey")) {
       return this.tableData.headers.dealer;
      }

      if (user_roles.includes("admin_survey")) {
       return this.tableData.headers.admin;
      }

      if (user_roles.includes("admin")) {
       return this.tableData.headers.admin;
      }
    },

    getDeadlineIcon(days) {
      if (days > 3) {
        return "mdi-clock";
      }

      if (days >= 1) {
        return "mdi-clock-time-ten";
      }

      return "mdi-clock-alert";
    },

    getDeadlineColor(days) {
      if (days > 3) {
        return "green";
      }

      if (days >= 1) {
        return "orange";
      }

      return "red";
    },

    setSearch: debounce(async function (e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const orderBy = sortBy.length ? sortBy[0] : "created_at";
      const sortDir = !sortDesc[0] ? "DESC" : "ASC";
      this.q = e.target.value;
      this.getItems(page, itemsPerPage, e.target.value, orderBy, sortDir);
    }, 300),

    async getItems(
      page,
      itemsPerPage,
      q = "",
      orderBy = "created_at",
      sortDir = "DESC"
    ) {
      let data = {
        page,
        per_page: itemsPerPage,
        order_by: orderBy,
        sort: sortDir,
      };
      if (q) {
        this.q = q;
        data.q = q;
      }
      await this.getSurveySmsBlasts(data).then((data) => {
        this.items = this.data.data.map((item) => {
          const percentage = item.target_recipients_count
            ? (item.count_survey_results / item.target_recipients_count) * 100
            : 0;

          const resendPercentage = item.target_recipients_count
            ? ((item.target_recipients_count - item.count_survey_results) /
                item.target_recipients_count) *
              100
            : 0;

          item.respondents_percentage = percentage.toFixed(2);
          item.resend_respondents_percentage = resendPercentage.toFixed(2);

          return item;
        });
        this.total = this.data.meta.total;
        this.loading = false;
        this.$refs.toolbar.items.isSearching = false;
      });
    },

    formatDate(item) {
      return helpers.format_date(item);
    },

    async sendSmsBlast(item, sendMethod) {
      this.sendLoading = true;

      await this.sendSurveySmsBlast({
        id: item.id,
        sendMethod,
      }).then(() => {
        this.sendLoading = false;
        this.previewDialog = false;
        this.dialogItem = {};
        this.e6 = 1;
      });

      await this.getItems(1, 5, "");
    },

    showBatchHistory(item, sendMethod = "send") {
      this.dialogItem = item;
      this.sendMethod = sendMethod;
      this.previewDialog = true;
    },

    download(item) {
      const self = this;
      let id = item.id;

      self.downloadId = item.id;
      self.downloading = true;

      self.downloadTextBlastLogs({ id }).then(function (data) {
        self.downloading = false;
        self.downloadId = null;
        window.location = data.data.url;
      });
    },
  },
};
</script>

<style></style>
